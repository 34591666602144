var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"},[_c('cash-account-movements-filters-drawer',{attrs:{"defaultFilters":_vm.defaultFilters,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},model:{value:(_vm.showFiltersDrawer),callback:function ($$v) {_vm.showFiltersDrawer=$$v},expression:"showFiltersDrawer"}})],1),_c('template',{slot:"content"},[_c('v-navigation-drawer',{attrs:{"right":"","temporary":"","fixed":"","width":"500"},model:{value:(_vm.showAddExpenseDrawer),callback:function ($$v) {_vm.showAddExpenseDrawer=$$v},expression:"showAddExpenseDrawer"}},[(_vm.showAddExpenseDrawer)?_c('cash-account-expense-form',{attrs:{"account":_vm.account,"active":_vm.showAddExpenseDrawer},on:{"update:active":function($event){_vm.showAddExpenseDrawer=$event}}}):_vm._e()],1),_c('cash-account-movement-view-drawer',{attrs:{"active":_vm.showMovementViewDrawer,"movement":_vm.selectedMovement},on:{"update:active":function($event){_vm.showMovementViewDrawer=$event}}}),_c('v-container',{staticClass:"py-6"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"flex-grow-0 pa-0"},[_c('v-btn',{staticClass:"black--text",attrs:{"icon":""},on:{"click":_vm.onBackSelected}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),(_vm.account && _vm.account.owner)?_c('span',{staticClass:"headline"},[_vm._v("Caja "+_vm._s(_vm.account.name)+" - "+_vm._s(_vm.account.owner.name))]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){_vm.showFiltersDrawer = !_vm.showFiltersDrawer}}},on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('span',[_vm._v("Filtrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-0",attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":_vm.onAddExpenseSelected}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Cargar Gasto")])])],1),_c('v-row',{staticClass:"py-4"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.filters.createdAt)?_c('span',{staticClass:"font-weight-regular grey--text"},[_vm._v(" Mostrando del "+_vm._s(_vm._f("moment")(_vm.filters.createdAt.gte,'dddd D [de] MMMM YYYY'))+" al "+_vm._s(_vm._f("moment")(_vm.filters.createdAt.lte,'dddd D [de] MMMM YYYY'))+" ")]):_vm._e()]),(_vm.account && _vm.account.balance)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('h5',{staticClass:"font-weight-regular grey--text"},[_vm._v("Balance")]),_c('span',{staticClass:"display-1 black--text"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.account.balance)))])]):_vm._e(),(_vm.filters.createdAt)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('h5',{staticClass:"font-weight-regular grey--text"},[_vm._v("Movimientos del periodo")]),_c('span',{staticClass:"display-1 black--text"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.stats.total)))])]):_vm._e()],1),_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","hide-default-header":_vm.$vuetify.breakpoint.xsOnly,"headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.movements,"items-per-page":-1,"sort-by":"id","sort-desc":""},on:{"click:row":_vm.onMovementSelected},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,'D/M/Y'))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount < 0)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.amount))+" ")]):(item.amount > 0)?_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.amount))+" ")]):_vm._e()]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.balance))+" ")]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [(item.ticket)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.getTicketPath(item.ticket),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-paperclip")])],1):_vm._e()]}},{key:"body.append",fn:function(){return [_c('tr',{on:{"click":_vm.onLoadMoreSelected}},[_c('td',{attrs:{"colspan":"4"}},[(_vm.isLoading)?_c('v-row',{attrs:{"justify":"center"}},[_c('hollow-dots-spinner',{attrs:{"animation-duration":1500,"size":64,"color":"#cddc39"}})],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"}},[_vm._v("Mostrar más")])],1)],1)])]},proxy:true}])})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }