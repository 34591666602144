<template>
    <template-layout>
        <template slot="drawers">
            <cash-account-movements-filters-drawer
                v-model="showFiltersDrawer"
                :defaultFilters="defaultFilters"
                :filters.sync="filters"
            >
            </cash-account-movements-filters-drawer>
        </template>

        <template slot="content">
            <v-navigation-drawer
                v-model="showAddExpenseDrawer"
                right
                temporary
                fixed
                width="500"
            >
                <cash-account-expense-form
                    v-if="showAddExpenseDrawer"
                    :account="account"
                    :active.sync="showAddExpenseDrawer"
                ></cash-account-expense-form>
            </v-navigation-drawer>

            <cash-account-movement-view-drawer
                :active.sync="showMovementViewDrawer"
                :movement="selectedMovement"
            />

            <v-container class="py-6">
                <v-row align="center">
                    <v-col class="flex-grow-0 pa-0">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>
                    <span class="headline" v-if="account && account.owner">Caja {{ account.name }} - {{ account.owner.name }}</span>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mr-4" icon @click="showFiltersDrawer = !showFiltersDrawer" v-on="on"><v-icon>mdi-tune</v-icon></v-btn>
                        </template>
                        <span>Filtrar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn fab x-small class="elevation-0" color="primary" @click="onAddExpenseSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                        </template>
                        <span>Cargar Gasto</span>
                    </v-tooltip>
                </v-row>

                <v-row class="py-4">
                    <v-col cols="12">
                        <span v-if="filters.createdAt" class="font-weight-regular grey--text">
                            Mostrando del {{ filters.createdAt.gte | moment('dddd D [de] MMMM YYYY') }} al {{ filters.createdAt.lte | moment('dddd D [de] MMMM YYYY') }}
                        </span>
                    </v-col>

                    <v-col cols="12" sm="4" v-if="account && account.balance">
                        <h5 class="font-weight-regular grey--text">Balance</h5>
                        <span class="display-1 black--text">{{ account.balance | toCurrency }}</span>
                    </v-col>

                    <v-col cols="12" sm="4" v-if="filters.createdAt">
                        <h5 class="font-weight-regular grey--text">Movimientos del periodo</h5>
                        <span class="display-1 black--text">{{ stats.total | toCurrency }}</span>
                    </v-col>
                </v-row>

                <v-card>
                    <v-card-text>
                        <v-data-table
                            hide-default-footer
                            :hide-default-header="$vuetify.breakpoint.xsOnly"
                            class="elevation-0"
                            :headers="headers"
                            :loading="isLoading"
                            :items="movements"
                            :items-per-page="-1"
                            sort-by="id"
                            sort-desc
                            @click:row="onMovementSelected"
                        >
                            <template v-slot:item.date="{ item }">
                                {{ item.date | moment('D/M/Y') }}
                            </template>

                            <template v-slot:item.amount="{ item }">
                                <span v-if="item.amount < 0" class="red--text">
                                    {{ item.amount | toCurrency }}
                                </span>

                                <span v-else-if="item.amount > 0" class="green--text">
                                    {{ item.amount | toCurrency }}
                                </span>
                            </template>

                            <template v-slot:item.balance="{ item }">
                                {{ item.balance | toCurrency }}
                            </template>

                            <template v-slot:item.icons="{ item }">
                                <a v-if="item.ticket" :href="getTicketPath(item.ticket)" target="_blank" style="text-decoration: none;">
                                    <v-icon>mdi-paperclip</v-icon>
                                </a>
                            </template>

                            <template v-slot:body.append>
                                <tr @click="onLoadMoreSelected">
                                    <td colspan=4>
                                        <v-row v-if="isLoading" justify="center">
                                            <hollow-dots-spinner :animation-duration="1500" :size="64" color="#cddc39" />
                                        </v-row>

                                        <v-row v-else justify="center">
                                            <v-btn text small color="primary">Mostrar más</v-btn>
                                        </v-row>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import CashAccountExpenseForm from './CashAccountExpenseForm';
    import CashAccountMovementViewDrawer from './CashAccountMovementViewDrawer';
    import CashAccountMovementsFiltersDrawer from './CashAccountMovementsFiltersDrawer';

    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import { HollowDotsSpinner } from 'epic-spinners';

    export default {
        components: {
            TemplateLayout,
            CashAccountExpenseForm,
            CashAccountMovementViewDrawer,
            CashAccountMovementsFiltersDrawer,
            HollowDotsSpinner
        },

        data() {
            return {
                showAddExpenseDrawer: false,
                showMovementViewDrawer: false,
                showFiltersDrawer: false,

                filters: {},
                defaultFilters: {},

                selectedMovement: null,

                headers: [
                    { text: 'Fecha', value: 'date', sortable: false },
                    { text: 'Notas', value: 'notes', sortable: false },
                    { text: 'Importe', value: 'amount', sortable: false },
                    { text: 'Balance', value: 'balance', sortable: false },
                    { text: '', value: 'icons', sortable: false }
                ],

                offset: 0
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['cashAccounts/isLoading']();
            },

            account() {
                return this.$store.getters['cashAccounts/getById'](parseInt(this.$route.params.id));
            },

            movements() {
                return this.account.movements;
            },

            stats() {
                return this.$store.getters['cashAccounts/getStats']();
            }
        },

        watch: {
            filters: {
                handler(val) {
                    // this.updateRouteQuery();
                    this.fetchMovements();
                },

                deep: true
            }
        },

        async mounted() {
            await this.$store.dispatch('cashAccounts/fetchOne', parseInt(this.$route.params.id));

            this.fetchMovements();
        },

        methods: {
            async fetchMovements() {
                this.$store.dispatch('cashAccounts/clearMovements', { account: this.account });
                await this.$store.dispatch('cashAccounts/fetchMovements', {
                    account: this.account,
                    start: -1,
                    limit: 20,
                    filters: this.filters
                });
            },

            getTicketPath(ticket) {
                return process.env.VUE_APP_TALLO_CDN + '/' + ticket;
            },

            onMovementSelected(movement) {
                this.showMovementViewDrawer = true;
                this.selectedMovement = movement;
            },

            onBackSelected() {
                if(window.history.length > 2) {
                    this.$router.go(-1);
                }
                else {
                    this.$router.push({ name: 'cashAccountList' });
                }
            },

            onAddExpenseSelected() {
                this.showAddExpenseDrawer = true;
            },

            onLoadMoreSelected() {
                if(this.isLoading) {
                    return;
                }

                let lastId = this.movements.reduce((min, movement) => {
                    return (movement.id < min || min === -1) ? movement.id : min;
                }, -1);

                this.$store.dispatch('cashAccounts/fetchMovements', {
                    account: this.account,
                    lastId: lastId,
                    limit: 20,
                    filters: this.filters
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
