<template>
    <div>
        <v-container class="px-6">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn small text class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Cargar Gasto</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-form>
                <v-text-field
                    v-model="concept"
                    label="Concepto"
                />

                <v-currency-field
                    v-model="amount"
                    label="Monto"
                    prefix="$"
                ></v-currency-field>

                <v-file-input
                    v-model="file"
                    label="Adjuntar ticket"
                    show-size
                />
            </v-form>
        </v-container>

        <v-container class="footer">
            <v-row class="px-4">
                <v-spacer></v-spacer>

                <v-btn color="success" @click="onConfirmSelected" :loading="isLoading">
                    Ingresar
                </v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        components: { },

        props: {
            account: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                concept: '',
                amount: 0,
                file: null
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['cashAccounts/isLoading']();
            }
        },

        watch: {

        },

        methods: {
            onConfirmSelected(value) {
                this.$store.dispatch('cashAccounts/addExpense', {
                    account: this.account,
                    expense: {
                        concept: this.concept,
                        amount: parseFloat(this.amount),
                        file: this.file
                    }
                }).then(() => {
                    return this.$store.dispatch('cashAccounts/fetchMovements', { account: this.account, start: -1, limit: 20 });
                }).then(() => {
                    this.$emit('update:active', false);

                    this.amount = 0;
                    this.concept = '';
                });
            },

            onBackSelected(value) {
                this.$emit('update:active', false);

                this.amount = 0;
                this.concept = '';
            }
        }
    };
</script>
