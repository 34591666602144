<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        fixed
        width="500"
        :app="$vuetify.breakpoint.xlOnly"
        :clipped="$vuetify.breakpoint.xlOnly"
        :temporary="$vuetify.breakpoint.lgAndDown"
    >
        <v-container class="px-6" v-if="movement">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Movimiento #{{ movement.id }}</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-text-subject</v-icon>Resumen</span>

                    <v-list dense class="t-info-list">
                        <v-list-item>
                            <v-list-item-content>Tipo</v-list-item-content>
                            <v-list-item-content class="align-end">{{ movement.operation }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>Fecha</v-list-item-content>
                            <v-list-item-content class="align-end">{{ movement.date | moment('D/M/Y H:m') }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>Monto</v-list-item-content>
                            <v-list-item-content class="align-end">{{ movement.amount | toCurrency }}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-comment-outline</v-icon>Comentario</span>
                </v-col>

                <v-col cols="12">
                    <span>{{ movement.notes }}</span>
                </v-col>
            </v-row>

            <v-row v-if="movement.ticket" class="mt-4">
                <v-col cols="12">
                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-paperclip</v-icon>Adjuntos</span>
                </v-col>

                <v-col cols="12">
                    <v-img
                        :src="getTicketFilePath(movement)"
                        contain
                    ></v-img>
                </v-col>
            </v-row>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
    export default {
        props: {
            active: {
                type: Boolean
            },

            movement: {
                type: Object
            }
        },

        data() {
            return {
            };
        },

        computed: {
            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            }
        },

        methods: {
            getTicketFilePath(movement) {
                return process.env.VUE_APP_TALLO_CDN + '/' + movement.ticket;
            },

            onBackSelected() {
                this.$emit('update:active', false);
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
