<template>
    <v-navigation-drawer
        v-model="active"
        disable-resize-watcher
        right
        temporary
        fixed
        width="600"
        style="z-index: 12"
    >
        <v-container fluid class="pl-6 pr-6">
            <v-row>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Filtros</span>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-menu v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px">

                        <template v-slot:activator="{ on }">
                            <v-text-field
                                label="Fecha"
                                color="accent"
                                v-model="dateRangeText"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="dateRange"
                            no-title
                            :first-day-of-week="1"
                            locale="es-AR"
                            range
                            event-color="green lighten-1"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <v-col>
                    <v-select
                        label="Tipo de operación"
                        v-model="newFilters.amount"
                        :items="optionsOperationType"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-col cols="6">
                    <v-btn color="orange" dark block @click="onResetSelected">Resetear</v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn color="green" dark block @click="onApplySelected">Aplicar</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: {
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            defaultFilters: Object,
            filters: Object
        },

        data() {
            return {
                active: false,
                dateMenu: false,

                dateRange: [],

                newFilters: {},

                optionsOperationType: [
                    { name: 'Todos', value: {} },
                    { name: 'Gastos', value: { lt: 0 } },
                    { name: 'Ingresos', value: { gt: 0 } }
                ]
            };
        },

        computed: {
            dateRangeText() {
                let dates = [ ...this.dateRange ];
                dates.sort();

                if(dates.length > 1) {
                    return dates.map(d => this.$moment(d).format('DD/MM/YYYY')).join(' al ');
                }
                else if(this.dateRange.length > 0) {
                    return this.$moment(this.dateRange[0]).format('DD/MM/YYYY');
                }
                else {
                    return null;
                }
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            filters: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.newFilters = { ...val };

                    if(this.newFilters.createdAt) {
                        if(typeof this.newFilters.createdAt === 'object') {
                            this.dateRange = [
                                ...(this.newFilters.createdAt.gte ? [this.newFilters.createdAt.gte] : []),
                                ...(this.newFilters.createdAt.lte ? [this.newFilters.createdAt.lte] : [])
                            ];
                        }
                        else {
                            this.dateRange = [ this.newFilters.createdAt ];
                        }
                    }
                }
            }
        },

        methods: {
            onApplySelected() {
                let dates = [ ...this.dateRange ];
                dates.sort();

                if(dates.length === 2) {
                    this.newFilters.createdAt = {
                        gte: this.$moment(dates[0]).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
                        lte: this.$moment(dates[1]).set({ hour: 23, minute: 59, second: 59, millisecond: 99 }).toISOString()
                    };
                }
                else if(dates.length === 1) {
                    this.newFilters.createdAt = {
                        gte: this.$moment(dates[0]).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
                        lte: this.$moment(dates[0]).set({ hour: 23, minute: 59, second: 59, millisecond: 99 }).toISOString()
                    };
                }

                this.$emit('update:filters', this.newFilters);
                this.$emit('input', false);
            },

            onResetSelected() {
                let defaultFilters = JSON.parse(JSON.stringify(this.defaultFilters));
                this.$emit('update:filters', defaultFilters);
                this.$emit('input', false);
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>

<style>

</style>
